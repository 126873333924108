/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

import * as style from './style/layout.module.scss'

const Layout = ({ children, isHome }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className = { style.body }>
      <Header 
        siteTitle={data.site.siteMetadata.title} 
        isHome = { isHome }
        />
      <div>
        <main>{children}</main>
        <footer className = { style.siteFooter }>

          <a
            href = 'mailto:manager@saraifiszel.com'>
            manager@saraifiszel.com
          </a>

          <a 
            className = { style.instagram }
            href = 'https://instagram.com/saraifiszel'></a>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
