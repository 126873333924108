import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment, useState, useEffect } from "react"

import * as style from './style/layout.module.scss'

const aboutText = React.createRef();

function Header({ siteTitle, isHome }) {
  const [about, displayAbout] = useState(false);

  let parts = siteTitle.split(' ');

  useEffect(() => {
    if (about) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = null;
    }
  }, [about]);

  return (
    <header className = { style.siteHeader } >
      <div className = { style.siteLogo }>
        <Link to="/">
          <em>{ parts[0] }</em> { parts[1] }
        </Link>
      </div>

      <div className = { style.headerSocials }>

        { isHome ? 
          <Fragment>
            <a 
              className = { style.aboutIcon }
              onClick = { (evt) => {
                evt.preventDefault();
                displayAbout(!about);
              }} >?</a>
          </Fragment>
        : null }

        { isHome ?
          <div 
            id = { style.About }
            className = { about ? style.showAbout : style.hideAbout }
            onClick = {(evt) => {
              if (aboutText.current.contains(evt.target))
                return;
              displayAbout(false);
            }}>
            <div 
              ref = { aboutText }
              className = { style.about }>
              <h1>{ siteTitle } - Makeup</h1>
              <p>
                Saraï spends her time between Paris, Los Angeles, Mexico and Havana - where she lives - constantly taking new inspirations from her trips. Over the past 20 years, she has worked with acknowledged professionals from the art, movies and luxury industry, creating and imposing her own style : ’effortless chic’.
              </p>
              <p>
                Countless directors, movie stars and global brands trust her daily with their makeup. Among them Wes Anderson, Cara Delevingne, Lily-Rose Depp, Ryan McGinley, Natalie Portman, Paolo Roversi, Léa Seydoux, Solve Sundsbo, Juergen Teller, Bruce Weber.
              </p>
            </div>
          </div>
        : null}

        <a
          className = { style.email }
          href = 'mailto:manager@saraifiszel.com'></a>

        <a 
          className = { style.instagram }
          href = 'https://instagram.com/saraifiszel'></a>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
