// extracted by mini-css-extract-plugin
export var siteLogo = "layout-module--siteLogo--c7ait";
export var siteHeader = "layout-module--siteHeader--gvSrg";
export var siteFooter = "layout-module--siteFooter--6VBY4";
export var body = "layout-module--body---MlTV";
export var instagram = "layout-module--instagram--Z1Wb2";
export var email = "layout-module--email--6z+83";
export var headerSocials = "layout-module--headerSocials--VSDKM";
export var aboutIcon = "layout-module--aboutIcon--TQ+K7";
export var About = "layout-module--About--i5SEM";
export var about = "layout-module--about--1YHRg";
export var hideAbout = "layout-module--hideAbout--rmV6B";